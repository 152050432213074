import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { apiClientState } from '../../../api/appState';

export interface CustomerRegistrationValues {
  city: string;
  companyName: string;
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonPhone: string;
  country: string;
  houseNumber1: string;
  houseNumber2: string;
  note: string;
  postalCode: string;
  regNo: string;
  street: string;
  taxNo: string;
  webSite: string;
}

export interface CustomerRegistrationErrors {
  city?: string;
  companyName?: string;
  contactPersonEmail?: string;
  contactPersonName?: string;
  contactPersonPhone?: string;
  country?: string;
  houseNumber1?: string;
  houseNumber2?: string;
  note?: string;
  postalCode?: string;
  regNo: string;
  street?: string;
  taxNo?: string;
}

export interface CustomerRegistrationTouched {
  city?: boolean;
  companyName?: boolean;
  contactPersonEmail?: boolean;
  // webSite?: boolean;
  contactPersonName?: boolean;
  contactPersonPhone?: boolean;
  country?: boolean;
  postalCode?: boolean;
  regNo?: boolean;
  street?: boolean;
  taxNo?: boolean;
}

export interface RegistrationHandler {
  (customerData: CustomerRegistrationValues): void;
}

export default function useRegistrationHandler(): (params: CustomerRegistrationValues) => Promise<{}> {
  const apiClient = useRecoilValue(apiClientState);
  return useCallback(
    (params: CustomerRegistrationValues) =>
      apiClient.post<CustomerRegistrationValues, CustomerRegistrationValues>('/v1/registration/create', params),
    [apiClient],
  );
}
