import TextField from '@sprinx/react-mui-fields/TextField';
import React from 'react';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import Typography from '@material-ui/core/Typography';
import FormikExtended, { FormikExtendedForwardProps } from '@sprinx/react-forms/FormikExtended';
import SelectField from '@sprinx/react-mui-fields/SelectField';
import SelectFieldItem from '@sprinx/react-mui-fields/SelectFieldItem';
import { AfterPageProps } from '../../../@sprinx/react-after-razzle';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import type {
  CustomerRegistrationValues,
  CustomerRegistrationErrors,
  CustomerRegistrationTouched,
} from './useRegistrationHandler';
import useRegistrationHandler from './useRegistrationHandler';

export type RegistrationPageProps = AfterPageProps;

const countryList = ['CZ', 'SK', 'DE', 'SR', 'HR', 'SL', 'HU'];

export interface LoginRegistrationProps
  extends Partial<
    FormikExtendedForwardProps<CustomerRegistrationValues, CustomerRegistrationErrors, CustomerRegistrationTouched>
  > {
  onClose: () => void;
  openDialog?: boolean;
}

const LoginRegistrationDialog: React.FC<LoginRegistrationProps> = ({ openDialog, onClose }) => {
  const t = useTranslateWithFallback();

  const [sentMessage, setSentMessage] = React.useState<{ isError: Boolean | undefined; text: string }>({
    isError: undefined,
    text: '',
  });

  const handleClose = React.useCallback(() => {
    onClose();
    setSentMessage({ isError: undefined, text: '' });
  }, [onClose]);

  const createCustomer = useRegistrationHandler();
  const handleSave = React.useCallback(
    async (values: CustomerRegistrationValues) => {
      createCustomer({ ...values })
        .then(() => {
          setSentMessage({ isError: false, text: t('loginPageRegistration/responses/success') });
        })
        .catch((err) => {
          setSentMessage({ isError: true, text: t('loginPageRegistration/responses/error') });
          console.error(err);
        });
    },
    [createCustomer, t],
  );

  return openDialog ? (
    <FormikExtended<CustomerRegistrationValues, CustomerRegistrationErrors, CustomerRegistrationTouched>
      onSave={handleSave}
      validateOnBlur
      validationFastest={{
        messages: {
          invalidCountryInTaxNo: 'loginPageRegistration/errors/invalidCountryInTaxNo',
          invalidTaxNoPattern: 'loginPageRegistration/errors/invalidTaxNoPattern',
        },
      }}
      initialValues={{
        regNo: '',
        taxNo: '',
        street: '',
        city: '',
        country: countryList[0] || 'CZ',
        postalCode: '',
        webSite: '',
        companyName: '',
        contactPersonName: '',
        contactPersonPhone: '',
        contactPersonEmail: '',
        note: '',
        houseNumber1: '',
        houseNumber2: '',
      }}
      validationSchema={{
        regNo: { type: 'string', empty: false },
        street: { type: 'string', empty: false },
        houseNumber1: { type: 'string', empty: true },
        houseNumber2: { type: 'string', empty: true },
        city: { type: 'string', empty: false },
        postalCode: { type: 'string', empty: false },
        country: { type: 'enum', values: countryList },
        webSite: { type: 'string', empty: true },
        contactPersonName: { type: 'string', empty: false },
        contactPersonPhone: { type: 'string', empty: false },
        contactPersonEmail: { type: 'email', empty: false },
        companyName: { type: 'string', empty: false },
        note: { type: 'string', empty: true },
        taxNo: {
          type: 'custom',
          check: function myCheck(
            value: string,
            errors: any[],
            field: any,
            context: CustomerRegistrationValues,
          ): boolean | string[] {
            if (!value) return true;

            if (/^[A-Z]{2}\d{8,10}$/.test(value) !== true) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('invalidTaxNoPattern');
            }
            if (value.slice(0, 2) !== context.country) {
              // eslint-disable-next-line react/no-this-in-sfc
              // @ts-ignore
              return this.makeError('invalidCountryInTaxNo');
            }
            return true;
          },
        },
      }}
      render={(formikProps) => (
        <WrapDialogForm
          {...formikProps}
          open
          disabled={sentMessage.isError != undefined}
          onClose={handleClose}
          dialogProps={{
            buttonSaveLabel: t('loginPageRegistration/sendRegistration'),
            buttonCancelLabel:
              sentMessage.isError != undefined ? t('loginPageRegistration/close') : t('loginPageRegistration/cancel'),
            fullScreen: true,
            maxWidth: 'md',
          }}
          render={() => {
            const { errors, onChange: handleChange, values } = formikProps;
            if (sentMessage.isError === false) {
              return <div>{sentMessage.text}</div>;
            } else if (sentMessage.isError === true) {
              return <div>{sentMessage.text}</div>;
            } else
              return (
                <>
                  <Typography variant='subtitle1' component='div' style={{ marginBottom: 4 }}>
                    {t('loginPageRegistration/registrationTitle')}
                  </Typography>
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/companyName')}
                    onChange={handleChange('companyName')}
                    value={values.companyName}
                    error={errors.companyName}
                    fullWidth
                    margin='normal'
                  />

                  <TextField
                    type='text'
                    label={t('loginPageRegistration/regNo')}
                    onChange={handleChange('regNo')}
                    value={values.regNo}
                    error={errors.regNo}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/taxNo')}
                    onChange={handleChange('taxNo')}
                    value={values.taxNo}
                    error={errors.taxNo}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/address')}
                    onChange={handleChange('street')}
                    value={values.street}
                    error={errors.street}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/houseNumber1')}
                    onChange={handleChange('houseNumber1')}
                    value={values.houseNumber1}
                    error={errors.houseNumber1}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/houseNumber2')}
                    onChange={handleChange('houseNumber2')}
                    value={values.houseNumber2}
                    error={errors.houseNumber2}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/city')}
                    onChange={handleChange('city')}
                    value={values.city}
                    error={errors.city}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/zipCode')}
                    onChange={handleChange('postalCode')}
                    value={values.postalCode}
                    error={errors.postalCode}
                    fullWidth
                    margin='normal'
                  />
                  <SelectField
                    value={values.country}
                    label={t('loginPageRegistration/country')}
                    onChange={handleChange('country')}
                    error={errors.country}
                    fullWidth
                    margin='normal'
                  >
                    {countryList.map((country, idx) => (
                      <SelectFieldItem key={idx} value={country}>
                        {country}
                      </SelectFieldItem>
                    ))}
                  </SelectField>
                  <TextField
                    type='text'
                    label={`${t('loginPageRegistration/website')} (${t('loginPageRegistration/optional')})`}
                    onChange={handleChange('webSite')}
                    value={values.webSite}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/contactPersonName')}
                    onChange={handleChange('contactPersonName')}
                    value={values.contactPersonName}
                    error={errors.contactPersonName}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/contactPersonPhone')}
                    onChange={handleChange('contactPersonPhone')}
                    value={values.contactPersonPhone}
                    error={errors.contactPersonPhone}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='email'
                    label={t('loginPageRegistration/contactPersonEmail')}
                    onChange={handleChange('contactPersonEmail')}
                    value={values.contactPersonEmail}
                    error={errors.contactPersonEmail}
                    fullWidth
                    margin='normal'
                  />
                  <TextField
                    type='text'
                    label={t('loginPageRegistration/note')}
                    onChange={handleChange('note')}
                    value={values.note}
                    error={errors.note}
                    fullWidth
                    margin='normal'
                  />
                </>
              );
          }}
        />
      )}
    />
  ) : null;
};

LoginRegistrationDialog.displayName = 'LoginRegistrationDialog';

export default LoginRegistrationDialog;
