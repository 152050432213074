import type { DocumentFile, DocumentFileNames, MultipleDocumentFileNames } from '../config';
import { files, multipleFiles, projectName } from '../config';
import type { SupportedLocale } from '../i18n/types';
import { routeUrl } from '../routesBuilder';

type LocaleDocumentFile = {
  alt: string;
  href?: string;
  src: string;
  title: string;
};

function getFile(file: DocumentFile, locale: SupportedLocale, projectName: string) {
  const keys = Object.keys(file);
  return keys.reduce((acc, key) => {
    const fileProp = file[key];
    // creating href from hrefProps if they are provided and href is not provided!
    if (!keys.includes('href') && key === 'hrefProps') {
      const url = routeUrl(fileProp.type, {
        locale,
        params: fileProp.options?.params ?? {},
        otherParamsToQs: fileProp.options?.otherParamsToQs ?? {},
      });
      acc['href'] = url;
    } else if (fileProp != null && typeof fileProp === 'object') {
      const value = fileProp[locale] || fileProp.default || '';
      // adding projectName to src due to public folder structure
      if (key === 'src') {
        acc[key] = value ? `/${projectName}` + value : '';
      } else {
        acc[key] = value;
      }
    } else {
      acc[key] = fileProp;
    }
    return acc;
  }, {} as LocaleDocumentFile);
}

export function getLocaleFile({
  documentName,
  locale,
  hrefRequired = false,
}: {
  documentName: DocumentFileNames;
  hrefRequired?: boolean;
  locale: SupportedLocale;
}): LocaleDocumentFile | null {
  const file = files[documentName];

  if (file === null) {
    return null;
  }

  if (file === undefined) {
    throw new Error(`File ${documentName} not found in config`);
  }

  const localeFile = getFile(file, locale, projectName);

  if (!localeFile.src) {
    throw new Error(`File ${documentName} not found in config`);
  }

  if (hrefRequired && !localeFile.href) {
    return null;
  }

  return localeFile;
}

export function getLocaleMultipleFiles({
  documentName,
  locale,
  hrefRequired = false,
}: {
  documentName: MultipleDocumentFileNames;
  hrefRequired?: boolean;
  locale: SupportedLocale;
}): LocaleDocumentFile[] | null {
  const fileArray = multipleFiles[documentName];
  if (fileArray === null) {
    return null;
  }

  if (fileArray === undefined) {
    throw new Error(`File ${documentName} not found in config`);
  }

  return fileArray
    .map((file) => getFile(file, locale, projectName))
    .filter((f) => f.src != '' && (!hrefRequired || f.href != null));
}
