import Typography from '@material-ui/core/Typography';
import FormikExtended, { FormikExtendedForwardProps } from '@sprinx/react-forms/FormikExtended';
import WrapDialogForm from '@sprinx/react-mui-components/WrapDialogForm';
import PriceField from '@sprinx/react-mui-fields/PriceField';
import TextField from '@sprinx/react-mui-fields/TextField';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { currencyState } from '../../../api/appState';
import { ShoppingCustomerContact } from '../../../api/shopping';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';
import CustomerProfilePageDeliveryPlaceSelector from './CustomerProfilePageDeliveryPlaceSelector';
import CustomerProfilePageUserSupervisorSelector from './CustomerProfilePageUserSupervisorSelector';
import {
  SubsidiaryUserCreateFormErrors,
  SubsidiaryUserCreateFormTouched,
  SubsidiaryUserCreateFormValues,
} from './useSubsidiaryUsersCreateHandler';

export interface CustomerProfilePageCreateSubsidiaryUserDialogProps
  extends Partial<
    FormikExtendedForwardProps<
      SubsidiaryUserCreateFormValues,
      SubsidiaryUserCreateFormErrors,
      SubsidiaryUserCreateFormTouched
    >
  > {
  onClose: () => void;
  onFormReleased: (p: any) => any;
  onSave: (createUserData: SubsidiaryUserCreateFormValues) => Promise<void>;
  open?: boolean;
  subsidiary: ShoppingCustomerContact;
}

const CustomerProfilePageCreateSubsidiaryUserDialog: React.FC<CustomerProfilePageCreateSubsidiaryUserDialogProps> = ({
  subsidiary,
  open,
  onClose,
  onFormReleased,
  ...rest
}) => {
  // const passwordStrongEnough = usePasswordStrongEnough();
  // const subsidiaryIdent = ((subsidiary.externals || [])[0] || '').replace('blyth://office/', '').split('/').join('-');
  const currency = useRecoilValue(currencyState);
  const t = useTranslateWithFallback();
  const address = subsidiary.postaddresses?.find((address) => address.type === 'HQ')?.place.full || null;

  return open ? (
    <FormikExtended<SubsidiaryUserCreateFormValues, SubsidiaryUserCreateFormErrors, SubsidiaryUserCreateFormTouched>
      {...rest}
      initialValues={{
        // username: subsidiaryIdent,
        name: '',
        email: '',
        orderTotalLimit: undefined,
        supervisor: undefined,
        deliveryPlaces: [subsidiary.id],
        // password: '',
        // confirmPassword: '',
      }}
      validationFastest={{
        messages: {
          confirmPassword: 'customerProfilePage/changePassword/confirmPassword',
          passwordStrongEnough: 'customerProfilePage/changePassword/passwordStrongEnough',
        },
      }}
      validationSchema={{
        name: { type: 'string', empty: false },
        email: { type: 'email' },
        orderTotalLimit: { type: 'number', optional: true },
        supervisor: { type: 'string', optional: true },
        deliveryPlaces: { type: 'array', items: 'string' },
        // password: {
        //   type: 'custom',
        //   check: function myCheckPassword(value: string): boolean | string[] {
        //     if (!passwordStrongEnough(value)) {
        //       // eslint-disable-next-line react/no-this-in-sfc
        //       // @ts-ignore
        //       return this.makeError('passwordStrongEnough');
        //     }
        //     return true;
        //   },
        // },
        // confirmPassword: {
        //   type: 'custom',
        //   check: function myCheck(
        //     value: string,
        //     schema: any,
        //     field: any,
        //     parent: SubsidiaryUserCreateFormValues,
        //   ): boolean | string[] {
        //     if (value !== parent.password) {
        //       // eslint-disable-next-line react/no-this-in-sfc
        //       // @ts-ignore
        //       return this.makeError('confirmPassword');
        //     }
        //     return true;
        //   },
        // },
      }}
    >
      {(formikProps) => (
        <WrapDialogForm
          {...formikProps}
          dialogTitle={t('customerProfilePage/createUserBranches')}
          open={open}
          onClose={onClose}
          dialogProps={{
            // buttonSaveLabel: '',
            fullScreen: true,
            maxWidth: 'md',
          }}
          render={() => {
            const { errors, onChange: handleChange, values } = formikProps;
            return (
              <>
                <Typography variant='subtitle1' component='div' style={{ marginBottom: 16 }}>
                  {`${subsidiary.officeName || subsidiary.name}${address != null ? ` - ${address}` : ''}`}
                </Typography>
                {/* <TextFieldPreview label={'Uživatelské jméno'} value={values.username} fullWidth /> */}
                <TextField
                  label={t('customerProfilePage/fullName')}
                  helperText={''}
                  value={values.name}
                  error={errors.name}
                  onChange={handleChange('name')}
                  fullWidth
                />
                <TextField
                  label={t('customerProfilePage/contactEmail')}
                  helperText={''}
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange('email')}
                  type='email'
                  fullWidth
                />
                <PriceField
                  currency={currency}
                  label={t('orders/order/priceLimit')}
                  helperText={''}
                  value={values.orderTotalLimit}
                  error={errors.orderTotalLimit}
                  onChange={handleChange('orderTotalLimit')}
                  fullWidth
                />
                <CustomerProfilePageUserSupervisorSelector
                  fullWidth
                  label={t('common/approver')}
                  customerId={subsidiary.id}
                  userId={'notYetCreated'}
                  value={values.supervisor}
                  onChange={handleChange('supervisor')}
                />
                <CustomerProfilePageDeliveryPlaceSelector
                  // customerId={subsidiary.id}
                  // userId={'notYetCreated'}
                  value={values.deliveryPlaces}
                  onChange={handleChange('deliveryPlaces')}
                />
                {/* <PasswordField
                  label={t('common/password'))}
                  helperText={''}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange('password')}
                  fullWidth
                />
                <PasswordField
                  label={t('common/passwordConfirm')}
                  helperText={''}
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  fullWidth
                /> */}
              </>
            );
          }}
        />
      )}
    </FormikExtended>
  ) : null;
};

CustomerProfilePageCreateSubsidiaryUserDialog.displayName = 'CustomerProfilePageCreateSubsidiaryUserDialog';

export default CustomerProfilePageCreateSubsidiaryUserDialog;
